import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductDialog from "@/view/pages/ProductDialog";
import OrderInfoDialog from "@/view/pages/OrderInfoDialog";
import GalleryDialog from "@/view/pages/GalleryDialog";
import moment from "moment";
import { mapGetters } from "vuex";
import ReferralApiService from "@/core/services/api.service.referral";

export default {
  name: "customer-referrals",
  computed: {
    computedItemKey() {
      // Generate a unique key for each row based on its index
      return (row) => `row-${row.index}`;
    },
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("menu.referralInvitation");
    },
    headers() {
      return [
        {
          text: "User initiator name",
          value: "name",
          sortable: false,
        },
        { text: "User ID", align: "start", sortable: false, value: "userId" },
        { text: "Referral link/code", value: "refferalLink", sortable: false },
        {
          text: "Program ID",
          value: "programId",
          sortable: false,
        },
        {
          text: "Total referrals",
          value: "invitedList.length",
          width: "10%",
          sortable: false,
        },
        /*  { text: "Verified / Non verified", value: "verified", sortable: false },
         */ { text: "Voucher status", value: "voucherStatus", sortable: false },
      ];
    },
    refItemHeaders() {
      return [
        { text: "Ref name", value: "name", sortable: false },
        /*      {
          text: "Verification status",
          value: "verStatus",
          sortable: false,
        },*/
        { text: "Device", value: "deviceId", sortable: false },
        {
          text: "Date of registration",
          value: "joined",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      status: "",
      totalReferrals: 0,
      referrals: [],
      loading: true,
      pagination: {
        page: 1,
        itemsPerPage: 100,
      },
      pageSizes: [10, 20, 50, 100],
      showProductDialog: false,
      shopProduct: null,
      showOrderInfoDialog: false,
      orderInfo: null,
      showGalleryDialog: false,
      errors: [],
      expandedItems: [],
      expandedRow: null,
      dateFormat: 'DD-MM-YYYY HH:mm',
    };
  },
  watch: {
    pagination: {
      handler() {
        this.getReferrals();
      },
      deep: true,
    },
  },
  async created() {
    if (!this.currentUser.superAdmin) return;
    this.loading = true;
    await ReferralApiService.init();
  },
  components: {
    ProductDialog,
    OrderInfoDialog,
    GalleryDialog,
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await ReferralApiService.init();
    await this.getReferrals();
  },
  methods: {
    expand(index) {
      // Toggle the expanded state
      this.expandedRow = this.expandedRow === index ? null : index;
    },

    getRequestParams() {
      const params = {};

      params["pageNum"] = this.pagination.page;
      params["pageSize"] = this.pagination.itemsPerPage;

      return params;
    },
    getReferrals() {
      const params = this.getRequestParams();
      // console.log(params);
      return ReferralApiService.getReferralsList(params)
        .then((response) => {
          //this.$log.debug('responss', response);
          const indexedData = response.data.map((item, i) => {
            item['index'] = i * this.pagination.page
            return item;
          })
          this.referrals = indexedData;
          this.totalReferrals = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
        })
        .catch((error) => {
          this.$log.error("Error: ", error);
          this.loading = false;
          this.referrals = [];
        })
        .finally(() => (this.loading = false));
    },
    parseDate(date) {
      if (date == null) return "";
      return moment.utc(date).local().format("yyyy-MM-DD HH:mm:ss");
    },
    formatUnixDate(num, format) {
      return moment(num * 1000).format(format || this.dateFormat);
    },
    padZero(value) {
      return value < 10 ? `0${value}` : value;
    },
  },
};

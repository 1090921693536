<template>
  <v-dialog v-model="show" max-width="450px">
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >{{$t("pages.orders.orderInfo")}}</span
          >
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("commonTable.productName")}}:</span>
          <a class="text-muted text-hover-primary">
            {{
              orderInfo != null &&
              orderInfo != null &&
              orderInfo.shopProduct
                ? orderInfo.shopProduct.name
                : ""
            }}</a
          >
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.colorSize")}}:</span>
          <a class="text-muted text-hover-primary">
            {{
              orderInfo != null &&
              orderInfo.shopProperties.length > 0
                ? orderInfo.shopProperties[0].name
                : ""
            }}
            {{
              orderInfo != null &&
              orderInfo.shopProperties.length > 1
                ? ", " + orderInfo.shopProperties[1].name
                : ""
            }}
          </a>
          <v-menu bottom offset-y>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="
                  orderInfo != null &&
                    orderInfo.shopProperties.length > 2
                "
                small
                class="mr-2"
                v-on="on"
                >mdi-dots-horizontal</v-icon
              >
            </template>
            <v-list
              v-if="orderInfo != null"
              dense
            >
              <v-list-item
                v-for="(item, i) in orderInfo.shopProperties"
                :key="i"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("common.quantity")}}:</span>
          <a class="text-muted text-hover-primary">{{
            orderInfo != null ? orderInfo.quantity : ""
          }}</a>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.orderOption")}}:</span>
          <span class="text-muted">{{
            orderInfo != null &&
            orderInfo.shopService != null
              ? orderInfo.shopService.name
              : ""
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.locationReservation")}}:</span>
          <span class="text-muted">{{
            orderInfo != null && orderInfo.shopOrder != null && orderInfo.shopOrder.shopLocation != null
              ? orderInfo.shopOrder.shopLocation.name +
                ", " +
                orderInfo.shopOrder.shopLocation.address
              : ""
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.totalPrice")}}:</span>
          <span class="text-muted">{{
            orderInfo != null &&
            orderInfo.shopProductPost != null
              ? Math.round(
                  orderInfo.shopProductPost.price *
                    orderInfo.quantity *
                    100
                ) /
                  100 +
                "€"
              : ""
          }}
          {{
              orderInfo != null &&
              orderInfo.shopProductPost == null
                  ? Math.round(
                  orderInfo.price *
                  orderInfo.quantity *
                  100
              ) / 100 + "€"
                  : ""
            }}
          </span>
        </div>
      </div>

      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >{{$t("pages.orders.customerInfo")}}</span
          >
        </h3>
      </div>
      <div class="card-body pt-0">
        <!--begin::customerInfo-->
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.customerName")}}:</span>
          <a class="text-muted text-hover-primary">{{
            orderInfo != null && orderInfo.shopOrder != null ? orderInfo.shopOrder.customerName : ""
          }}</a>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.customerAddress")}}:</span>
          <span class="text-muted">{{ orderInfo != null && orderInfo.shopOrder != null ? orderInfo.shopOrder.customerAddress : ""}}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between">
          <span class="font-weight-bold mr-2">{{$t("pages.orders.customerPhone")}}:</span>
          <span class="text-muted">{{ orderInfo != null && orderInfo.shopOrder != null ? orderInfo.shopOrder.customerPhone : ""}}</span>
        </div>
        <!--end::Contact-->
      </div>
      <!--end::Body-->
    </div>
  </v-dialog>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  props: {
    modal: {
      default: false
    },
    orderInfo: {
      type: Object
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Order Info" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
</script>
